const local = {
  url: 'http://localhost:8000/graphql/miele',
  login: {
    guest: 'http://localhost:8000/api/login/guest',
    user: 'http://localhost:8000/api/login_check',
    test: 'http://localhost:8000/api/test'
  }
}

const dev = {
  url: 'https://api.ps30-dev.c1.ps30.de/graphql/miele',
  login: {
    guest: 'https://api.ps30-dev.c1.ps30.de/api/login/guest',
    user: 'https://api.ps30-dev.c1.ps30.de/api/login_check',
    test: 'https://api.ps30-dev.c1.ps30.de/api/test'
  }
}

const prod = {
  url: 'https://api.ps30.c1.ps30.de/graphql/miele',
  login: {
    guest: 'https://api.ps30.c1.ps30.de/api/login/guest',
    user: 'https://api.ps30.c1.ps30.de/api/login_check',
    test: 'https://api.ps30.c1.ps30.de/api/test'
  }
}


const config = process.env.REACT_APP_STAGE === 'local' ? local : (process.env.REACT_APP_STAGE === 'dev' ? dev : prod);


export const graphql = config;
